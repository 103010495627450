
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {IcraTakipMahiyetleriCevapRow} from "@/plugins/uyap-plugin/uyap/IcraTakipMahiyetleri";

@Component({
  components: {FormWrapper}
})
export default class MahiyetSecForm extends Mixins(SimpleInputMixin) {
  @Prop() mahiyetler!: Array<{ mahiyetTuru: string, items: Array<IcraTakipMahiyetleriCevapRow> }>

  localValue: Array<{ mahiyetTuru: string, value: number }> = [];

  mahiyetInput(tur: string, value: number) {
    let findMahiyet = this.localValue.find(mahiyet => mahiyet.mahiyetTuru == tur);
    if (findMahiyet)
      findMahiyet.value = value;
    else
      this.localValue.push({mahiyetTuru: tur, value: value});
  }
}
